/** @jsxRuntime classic /
 /* @jsx jsx */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import "swiper/css/swiper.css"
/** @jsx jsx */
// @ts-ignore
import { jsx } from "@emotion/core"

import vantaImage from "../images/vanta_image.webp"

import "./index.css"

const TextTitle = styled.h1`
  ${tw`text-black text-9/2xl text-center font-normal`}
  @media (max-width: 767px) {
    ${tw`text-8`}
  }
`

const TextStandard = styled.h3`
  ${tw`text-sm text-center font-normal`}
  color: #72707A;
  @media (max-width: 767px) {
    ${tw`text-5`}
  }
`

const AnnouncementContainer = styled.div`
  margin-top: 56px;
  background: white;
    font-family: Avenir;
    border-radius: 8px;
    border: 1px solid #C6C6C6;
    display: flex;
    .image-container {
        flex: 0.5;
        align-self: start;
        img {
            height: 100%;
            border-radius: 0;
            object-fit: contain;
        }
        @media (max-width: 769px) {
            display: none;
        }
    }
    }
    .text-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 45px 24px 45px;
        align-items: start;
        .title {
            font-size: 1.5em;
            color: #0F1722;
            font-weight: 600;
            margin-bottom: 8px;
        }
        .content {
            margin-top: 24px;
            font-size: 1em;
            text-align: start;
        }
    }
`

const Announcements = () => {
  return (
    <React.Fragment>
      <AnnouncementContainer>
        <div className="image-container">
          <img src={vantaImage} alt={"Vanta Logo"} className="object-contain" />
        </div>
        <div className="text-container">
          <TextTitle className="title text-left">
            AirMason Inc. successfully receives SOC 2 Type II attestation report
          </TextTitle>
          <TextStandard className="date">September 30th, 2024</TextStandard>
          <TextStandard className="content">
            We’re pleased to announce the completion of Airmason’s 2024 SOC 2
            Type II audit. The SOC 2 Type II Audit is a comprehensive 3rd party
            objective audit that addresses security, availability, processing
            integrity, confidentiality, privacy, as well as strict internal
            controls.
          </TextStandard>

          <p className="font-semibold my-2">
            Audit Criteria: What HR Leaders Need to Know
          </p>
          <TextStandard className="content mt-0">
            SOC 2 (Service Organization Control 2) is an auditing procedure that
            ensures service providers securely manage data. For HR
            professionals, it's crucial in safeguarding employee information.
            The audit covers five key areas:
          </TextStandard>
          <TextStandard className="content mt-2">
            <ol className="text-left">
              <li>
                <span className="font-semibold">Security</span>: Protecting HR
                systems and employee data from unauthorized access.{" "}
              </li>
              <li>
                <span className="font-semibold">Availability</span>: Ensuring
                the AirMason platform is accessible when needed.
              </li>
              <li>
                <span className="font-semibold">Processing Integrity</span>:
                Guaranteeing HR data processing is accurate and timely.
              </li>
              <li>
                <span className="font-semibold">Confidentiality</span>:
                Safeguarding sensitive information at all times, and limiting
                access.
              </li>
              <li>
                <span className="font-semibold">Privacy</span>: Handling
                personal employee information responsibly and having
                best-in-class encryption.
              </li>
            </ol>
          </TextStandard>
          <TextStandard className="content mt-2">
            Airmason has been granted certifications without exceptions by an
            independent auditor, validating our robust, company-wide security
            and compliance measures. This unqualified approval reinforces our
            commitment to comprehensive safeguards and our status as a trusted
            partner.
          </TextStandard>
        </div>
      </AnnouncementContainer>
    </React.Fragment>
  )
}

const index = () => {
  return (
    <div className="text-center mb-16 px-2">
      <TextTitle>Enterprise Security and Privacy at AirMason</TextTitle>
      <Announcements />
    </div>
  )
}

export default index
